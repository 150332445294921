<template>
  <div class="main">
    <div class="map">
      <l-map
        ref="map"
        @ready="onMapReady()"
        :zoom="zoom"
        :center="center"
      >
        <l-tile-layer
          :url="url"
          :attribution="attribution"
        ></l-tile-layer>
      </l-map>
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { mapState } from 'vuex';

export default {
  components: {},
  data() {
    return {
      map: null,
      isMapReady: false,
      url: 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      attribution:
        'Map data &copy; <a href="https://www.mapbox.com/">Mapbox</a>',
      zoom: 13,
      center: [10.830199566229453, 106.66768103053242], // init default location
      accessToken:
        'pk.eyJ1IjoicmljaGFyZGh1eW5oIiwiYSI6ImNsamUxY296ZTA3YWIzZXRla2xleHFwdmoifQ.vsQrgsYR84lFvD-i82VYmw',
    };
  },
  computed: {
    ...mapState({
      shipment: (state) => state.shipment.shipment,
    }),
  },
  created() {
    this.url = this.url.replace('{id}', 'mapbox/streets-v12');
    this.url = this.url.replace('{accessToken}', this.accessToken);
  },
  mounted() {},
  methods: {
    initializeRoutes() {
      const originIcon = L.icon({
        iconUrl: 'media/svg/icons/Map/origin.svg',
        iconSize: [20, 20],
        iconAnchor: [20, 20],
      });
      const wayPoint = [];
      const origin = L.latLng(this.shipment.latitude, this.shipment.longitude);
      this.center[0] = this.shipment.longitude;
      this.center[1] = this.shipment.latitude;
      wayPoint.push(origin);
      this.shipment.destinations.forEach((des) => {
        const destination = L.latLng(des.latitude, des.longitude);
        wayPoint.push(destination);
      });
      // add routing
      L.Routing.control({
        waypoints: wayPoint,
        router: L.Routing.mapbox(this.accessToken),
        createMarker: function (i, waypoint) {
          if (i === 0) {
            return L.marker(waypoint.latLng, {
              icon: originIcon,
            });
          } else {
            // Use default marker for intermediate waypoints
            return L.marker(waypoint.latLng);
          }
        },
        routeWhileDragging: true,
      }).addTo(this.map);
    },
    onMapReady() {
      this.map = this.$refs.map.mapObject;
      this.initializeRoutes();
    },
  },
};
</script>

<style lang="scss" scoped>
.main ::v-deep {
  .map {
    height: 66vh;
  }
}
</style>
