<template>
  <div class="main">
    <KTCodePreview :title="''">
      <template v-slot:preview>
        <!-- navigate -->
        <b-card
          no-body
          class="full-width main-card"
        >
          <div
            class="wizard wizard-4"
            id="kt_wizard_v4"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Nav-->
            <div
              class="wizard-nav"
              style="background-color: #eef0f8"
            >
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-label">
                      <div class="wizard-title">Thông tin vận chuyển</div>
                    </div>
                  </div>
                </div>
                <div
                  class="wizard-step ml-1"
                  data-wizard-type="step"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-label">
                      <div class="wizard-title">Thông tin gói hàng</div>
                    </div>
                  </div>
                </div>
                <div
                  class="wizard-step ml-1"
                  data-wizard-type="step"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-label">
                      <div class="wizard-title">Lịch trình di chuyển</div>
                    </div>
                  </div>
                </div>
                <div
                  class="wizard-step ml-1"
                  data-wizard-type="step"
                  v-if="shipmentId"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-label">
                      <div class="wizard-title">Tệp đính kèm</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- tab general info -->
            <div class="tab-content">
              <div
                class="info"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <ShipmentInfo :configs="distanceFareConfigs" />
              </div>
              <!-- tab general info -->
              <div
                class="package"
                data-wizard-type="step-content"
              >
                <ShipmentPackage />
              </div>
              <div
                class="map"
                data-wizard-type="step-content"
              >
                <div v-if="activeTab === 3">
                  <Map />
                </div>
              </div>
              <!-- tab attach image -->
              <div
                class="image"
                data-wizard-type="step-content"
              >
                <UploadFile
                  :id="shipmentId"
                  :entity="'bills'"
                />
              </div>
            </div>
          </div>
        </b-card>
      </template>

      <!-- page footer -->
      <template v-slot:foot>
        <div class="footer">
          <b-button
            v-if="checkPermissions(['SHIPMENT_UPDATE'])"
            class="cta"
            variant="primary"
            size="sm"
            type="submit"
            @click="onSubmit"
          >Lưu</b-button>
          <b-button
            class="cta ml-4"
            variant="secondary"
            size="sm"
            @click="onCancel"
          >Huỷ</b-button>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import KTWizard from '@/assets/js/components/wizard';
import UploadFile from '@/view/modules/upload/Upload';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ShipmentInfo from '@/view/components/shipment/ShipmentInfo.vue';
import ShipmentPackage from '@/view/components/shipment/ShipmentPackage.vue';
import Map from '@/view/components/map/Map.vue';
import { mapActions, mapState } from 'vuex';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import ApiService from '@/core/services/api.service';
import localData from '@/utils/saveDataToLocal';
import { checkPermissions } from '@/utils/saveDataToLocal';

export default {
  components: {
    UploadFile,
    KTCodePreview,
    ShipmentInfo,
    ShipmentPackage,
    Map,
  },
  data() {
    return {
      isSubmitting: false,
      isLoading: false,
      wizard: null,
      activeTab: 1,
      pageTile: 'Thông tin chuyến hàng',
      shipmentId: null,
      distanceFareConfigs: null,
    };
  },
  watch: {
    'wizard.currentStep': {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;
        this.activeTab = newVal;
      },
      deep: true,
    },
  },
  async created() {
    await this.initializeData();
  },
  computed: {
    ...mapState({
      shipment: (state) => state.shipment.shipment,
    }),
  },
  mounted() {
    this.wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1,
      clickableSteps: true,
    });

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Vận chuyển', route: '/' },
      { title: 'Danh sách vận chuyển', route: '/' },
      { title: 'Thông tin vận chuyển', route: '/' },
    ]);
  },
  methods: {
    checkPermissions,
    ...mapActions(['getShipmentAction', 'getExistingShipmentAction']),
    create() {
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      ApiService.post(`/v2/shipments`, this.shipment)
        .then(({ data }) => {
          makeToastSuccess(data.message);
          this.$router.push({
            path: '/shipment',
          });
        })
        .catch((err) => {
          if (!err.response) {
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.isSubmitting = false;
        });
    },
    update() {
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      ApiService.put(`/v2/shipments`, this.shipment)
        .then(({ data }) => {
          makeToastSuccess(data.message);
          this.$router.push({
            path: '/shipment',
          });
        })
        .catch((err) => {
          if (!err.response) {
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.isSubmitting = false;
        });
    },
    onSubmit() {
      if (this.shipmentId) {
        this.update();
        return;
      }
      this.create();
    },
    handleSuccess() {
      this.isLoading = false;
    },
    handleFail() {
      this.isLoading = false;
    },
    async fetchDistanceFareConfig() {
      await ApiService.get('v2/distance-fare-configs').then((response) => {
        this.distanceFareConfigs = response.data.data.dataset;
      });
    },
    async initializeData() {
      let { entities } = this.$route.params;
      this.isLoading = true;
      this.shipmentId = this.$route.query.shipmentId;
      await this.fetchDistanceFareConfig();
      if (this.shipmentId) {
        // get existing shipment
        return this.getExistingShipmentAction({
          params: { shipmentId: this.shipmentId },
          success: this.handleSuccess,
          fail: this.handleFail,
        });
      }
      if (!entities) {
        entities = JSON.parse(localData.getData('shipment-entities'));
      }
      this.getShipmentAction({
        params: entities,
        success: this.handleSuccess,
        fail: this.handleFail,
      });
    },
    onCancel() {
      history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/pages/wizard/wizard-4.scss';

.main ::v-deep {
  .main-card {
    border-bottom: none;
  }
  .card-body {
    padding: 0;
  }
  .card-footer {
    padding: 20px 0;
  }
  .tab-content {
    .info,
    .package,
    .map,
    .image {
      padding: 20px;
    }
  }
  .footer {
    margin-left: 20px;
    .cta {
      font-weight: 600;
      width: 70px;
    }
  }
}
</style>